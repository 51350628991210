<template>
  <div class="chart"></div>
</template>

<script>
// http://datav.aliyun.com/portal/school/atlas/area_selector  地图生成器网址
import mapJson from '/src/assets/jx.json'
import * as echarts from 'echarts'
export default {
  name: 'echarts',
  data () {
    return {
      mapColor: '',
      // !注意数据和json里面的数据一定要相匹配
      dataValue: [
        { name: '城阳区', value: 8 },
        { name: '平湖市', value: 28 },
        { name: '秀洲区', value: 48 },
        { name: '南湖区', value: 68 },
        { name: '海盐县', value: 88 },
        { name: '桐乡市', value: 108 },
        { name: '海宁市', value: 128 }
      ]
    }
  },
  mounted () {
    this.echartsInit()
  },
  methods: {
    echartsInit () {
      // 这里填写json文件数据
      echarts.registerMap('jx', mapJson)

      echarts.init(this.$el).setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}<ba/>{c}可调负荷'
        },
        title: {
          // text: "嘉兴市",
          x: 'center'
        },
        geo: {
          map: 'jx',
          label: {},
          roam: true,
          itemStyle: {}
        },
        visualMap: {
          min: 0,
          max: 108,
          realtime: false,
          calculable: true,
          inRange: {
            color: ['#326343', '#b06b64']
          }
        },
        series: [
          {
            // !显示字体修改这两个地方
            type: 'map',
            map: 'jx',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'stroke'
            },
            symbolSize: function (val, params) {
              return 8
            },
            data: this.dataValue,
            label: {
              normal: {
                show: true, // 显示省份标签
                textStyle: { color: '#fff' } // 省份标签字体颜色
              },
              emphasis: {
                // 对应的鼠标悬浮效果
                show: true,
                textStyle: { color: '#800080' }
              }
            },
            itemStyle: {
              normal: {
                borderWidth: 0.5, // 区域边框宽度
                borderColor: '#009fe8', // 区域边框颜色
                // areaColor: 'red' // 区域颜色
                areaColor: this.mapColor // 区域颜色
              },
              emphasis: {
                borderWidth: 0.5,
                borderColor: '#4b0082',
                areaColor: '#f47920'
              }
            }
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.chart {
  height: 500px;
  width: 100%;
}
</style>
